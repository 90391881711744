@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  width:100%;
  height:auto;
  color: #fcfcfc;
  background-color:black;
  overflow:hidden;
}

#gensoup {
  width:100%;
  height:100%;
  min-width:320px;
  min-height:320px;
  margin:auto;
}
